#cte_p{
    margin-top: 10px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
p{
    cursor: default;
    font-size: 18px;
    color: #eee;
    letter-spacing: 0.1rem;
}
.pp{
    padding: 3rem 0 7rem 0!important;
    background-color: #111;
    position: relative;
    flex-direction: column;
	align-content: center;
	justify-content: space-between;
}
h1 {
    color: #aaa;
    font-size: 3rem;
    letter-spacing: 00.2rem;
}

@media (max-width: 360px) {
    #cte_p p{
        font-size: 14px;
    }
 }

 @media (max-width: 260px) {
    #cte_p p{
        font-size: 10px;
    }
 }