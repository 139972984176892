#el_foot {
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
#el_foot div{
    margin: 3rem 0rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

#el_foot div img {
    cursor: pointer;
    width: 40px;
    height: 40px;
}

#el_foot p {
    color: rgba(28,28,28,.69);
}