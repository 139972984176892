*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
h1 {
	font-family: 'Darker Grotesque', sans-serif;
	font-weight: 500;
	margin: 4rem 0;
}
h3 {
	color: #eee;
    font-size: 1.3rem;
	margin: 0 0 1rem 0;
}
/* Locomotive */
html.has-scroll-smooth {
	overflow: hidden;
}
html.has-scroll-dragging,
html.has-scroll-dragging * {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.has-scroll-smooth body {
	overflow: hidden;
}
.c-scrollbar {
	position: absolute;
	right: 0;
	top: 0;
	width: 11px;
	height: 100vh;
	transform-origin: center right;
	transition: transform 0.3s, opacity 0.3s;
	opacity: 0;
}
.c-scrollbar:hover {
	transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-dragging .c-scrollbar,
.has-scroll-scrolling .c-scrollbar {
	opacity: 1;
}
.c-scrollbar_thumb {
	position: absolute;
	top: 0;
	right: 0;
	background-color: #000;

	width: 7px;

	margin: 2px;
	cursor: -webkit-grab;
	cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

/* Body */
body {
	--color-text: #fff;
	--color-bg: rgb(2,0,36);
	--color-link: #aaa;
	--color-link-hover: #fff;
	--color-alt: #7f6a57;
	color: var(--color-text);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	counter-reset: contentSection;
	font-family: neuzeit-grotesk, sans-serif;
}

/* Page Loader */
.loading::before,
.loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.loading::before {
    content: 'Dr. Alfredo Ismael Román Masso';
    text-align: center;
    color: var(--color-link);
    font-weight: 700 !important;
    font-size: 1.1rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 125px !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.loading::after {
	top: 50%;
	left: 50%;
	width: 80px;
	height: 80px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

button:focus {
	background-color: transparent !important;
}
.video-container {
	position: relative;
}
.video-container .mirror-active {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	cursor: pointer;
  }
.video-container .mirror-pasive {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 83%;
	background-color: transparent;
	cursor: pointer;
  }
.video__stripped {
	background-color: rgba(0, 0, 0, .2);
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	width: 100%;
	height: 100%;
}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

@media (max-width: 1000px) {
	.video-container .mirror-pasive {
		height: 75%;
	}
}

@media (max-width: 750px) {
	.video-container .mirror-pasive {
		height: 68%;
	}
}

@media (max-width: 600px) {
	.i_tes{
	  border-right: 0px !important;
	}
	.video-container .mirror-pasive {
		height: 0%;
	}
}

@media (max-width: 380px) {
	h1 {
	  font-size: 2rem !important;
	}
}