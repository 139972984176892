#main{
    text-align: center;
}
#main h1{
    font-size: 40px;
    color: #fff;
    font-family: 'Ubuntu', sans-serif;
}
#main h5{
    font-size: 20px;
    color: #F0F0F0;
    font-family: 'Ubuntu', sans-serif;
}
#cabecera1{
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 9%, rgba(9,9,121,1) 48%, rgba(0,212,255,1) 100%);
}
#main_photo{
    pointer-events: none;
    border-radius: 50%;
    margin: 20px 0px;
    -webkit-box-shadow: 2px 2px 3px 4px;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    2px 2px 3px 4px;  /* Firefox 3.5 - 3.6 */
    box-shadow:         2px 2px 3px 4px;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
#contacto{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
#contacto img{
    max-width: 7% !important;
    margin-right: 5px;
}
#los-logos{
    background-color: #FFF;
    border-radius: 15px;
    padding: 5px 0px;
    margin: 3rem 0px 3rem 0px;
    -webkit-box-shadow: 3px 3px 3px 3px rgba(0,0,0,.5);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 3px 3px 3px rgba(0,0,0,.5);  /* Firefox 3.5 - 3.6 */
    box-shadow:         3px 3px 3px 3px rgba(0,0,0,.5);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.content-logos{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.content-logos #electro{
    max-width: 40% !important;
}
.content-logos #maroma{
    max-width: 20% !important;
}
.content-logos #trescom{
    max-width: 30% !important;
}
@media (max-width: 670px) {
    #main h1{
        font-size: 30px;
    }
}
/*  ANIMATIONS */

.pulse{
	animation-name: pulse;
	-webkit-animation-name: pulse;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.9);
		opacity: 0.7;		
	}
	50% {
		transform: scale(1);
		opacity: 1;	
	}	
	100% {
		transform: scale(0.9);
		opacity: 0.7;	
	}			
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;		
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;	
	}			
}
.floating{
	animation-name: floating;
	-webkit-animation-name: floating;

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes floating {
	0% {
		transform: translateY(0%);	
	}
	50% {
		transform: translateY(8%);	
	}	
	100% {
		transform: translateY(0%);
	}			
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translateY(0%);	
	}
	50% {
		-webkit-transform: translateY(8%);	
	}	
	100% {
		-webkit-transform: translateY(0%);
	}			
}
.tossing{
	animation-name: tossing;
	-webkit-animation-name: tossing;	

	animation-duration: 2.5s;	
	-webkit-animation-duration: 2.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
	0% {
		transform: rotate(-4deg);	
	}
	50% {
		transform: rotate(4deg);
	}
	100% {
		transform: rotate(-4deg);	
	}						
}

@-webkit-keyframes tossing {
	0% {
		-webkit-transform: rotate(-4deg);	
	}
	50% {
		-webkit-transform: rotate(4deg);
	}
	100% {
		-webkit-transform: rotate(-4deg);	
	}				
}