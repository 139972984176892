.tiles {
	--tiles-height: 52vw;
	height: var(--tiles-height);
	position: relative;
	overflow: hidden;
}
.tiles__wrap {
	width: 150%;
	--tileswrap-height: var(--tiles-height);
	height: var(--tileswrap-height);
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%,-50%, 0);
}
.tiles__line {
	display: flex;
}
.tiles__line-img {
	--tile-margin: 2vw;
	flex: none;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc( var(--tileswrap-height) / 3 - (3 * var(--tile-margin) / 2));
	margin: var(--tile-margin);
	background-size: cover;
	background-position: 50% 50%;
}