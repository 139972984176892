.cont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.button {
	pointer-events: auto;
	cursor: pointer;
	background: #e7e7e7;
	border: none;
	padding: 1.5rem 3rem;
	margin: 1rem 0px;
	font-family: inherit;
	font-size: inherit;
	position: relative;
	display: inline-block;
}

.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.button--mimas {
	text-transform: uppercase;
	letter-spacing: 0.05rem;
	font-weight: 700;
	font-size: 0.85rem;
	border-radius: 0.5rem;
	overflow: hidden;
	color: #fff;
	background: #e7e7e7;
}

.button--mimas span {
	position: relative;
	mix-blend-mode: difference;
}

.button--mimas::before {
	content: '';
	background: #201C17;
	width: 120%;
	left: -10%;
	transform: skew(30deg);
	transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.button--mimas:focus {
	animation: colorChangue .6s ease-out;
}

.button--mimas:hover::before {
	transform: translate3d(100%,0,0);
}

@keyframes colorChangue {
	0% {
		color: #1b1b1b;
	}
	100% {
		color: #fff;
	}
}