video::-internal-media-controls-download-button {
    display:none;
}
video::-webkit-media-controls-enclosure {
    overflow:hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px); 
}
.button--janus {
	font-family: freight-display-pro, serif;
	font-weight: 900;
	width: 175px;
	height: 120px;
	color: #fff;
	background: none;
}

.button--janus::before {
	content: '';
	background: #BABABA;
	-webkit-clip-path: path("M154.5,88.5 C131,113.5 62.5,110 30,89.5 C-2.5,69 -3.5,42 4.5,25.5 C12.5,9 33.5,-6 85,3.5 C136.5,13 178,63.5 154.5,88.5 Z");
	clip-path: path("M154.5,88.5 C131,113.5 62.5,110 30,89.5 C-2.5,69 -3.5,42 4.5,25.5 C12.5,9 33.5,-6 85,3.5 C136.5,13 178,63.5 154.5,88.5 Z");
	transition: clip-path 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55), -webkit-clip-path 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55), background 0.5s ease;
}

.button--janus:hover::before {
	background: #000;
	-webkit-clip-path: path("M143,77 C117,96 74,100.5 45.5,91.5 C17,82.5 -10.5,57 5.5,31.5 C21.5,6 79,-5.5 130.5,4 C182,13.5 169,58 143,77 Z");
	clip-path: path("M143,77 C117,96 74,100.5 45.5,91.5 C17,82.5 -10.5,57 5.5,31.5 C21.5,6 79,-5.5 130.5,4 C182,13.5 169,58 143,77 Z");
}

.button--janus::after {
	content: '';
	height: 86%;
	width: 97%;
	top: 5%;
	border-radius: 58% 42% 55% 45% / 56% 45% 55% 44%;
	border: 1px solid #000;
	transform: rotate(-20deg);
	z-index: -1;
	transition: transform 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
}

.button--janus:hover::after {
	transform: translate3d(0,-5px,0);
}

.button--janus span {
	display: block;
	transition: transform 0.3s ease;
	mix-blend-mode: difference;
}

.button--janus:hover span {
	transform: translate3d(0,-10px,0);
}
#desc_cvs{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
#content_cvs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
#content_cvs img{
    pointer-events: none;
    width: 170px;
    height: 220px;
    margin-bottom: 1rem;
}
@media (max-width: 1130px) {
    #content_cvs img{
        width: 120px;
        height: 170px;
    }
}
@media (max-width: 430px) {
    #content_cvs img{
        width: 70px;
        height: 120px;
    }
}